import t from './actionTypes'

// Opens or closes the referral modal on the menu
export function toggleReferralModal() {
  return {
    type: t.TOGGLE_REFERRAL_MODAL
  }
}

// Opens the iOS vapes redirect modal and sets the path the button should redirect to
export function openIosVapesRedirectModal(redirectPath: string) {
  return {
    type: t.OPEN_IOS_VAPES_REDIRECT_MODAL,
    payload: redirectPath
  }
}

// Closes the iOS vapes redirect modal
export function closeIosVapesRedirectModal() {
  return {
    type: t.CLOSE_IOS_VAPES_REDIRECT_MODAL
  }
}

// closes BerbixModal  if it's open, and open it if it's closed
export function toggleBerbixModal() {
  return (dispatch, getState) => {
    const berbixModalState = getState().modal.isBerbixModalOpen

    if (berbixModalState) {
      dispatch(closeBerbixModal())
    } else {
      dispatch(openBerbixModal())
    }
  }
}

// open the berbix modal
export function openBerbixModal() {
  return {
    type: t.OPEN_BERBIX_MODAL
  }
}

// close the berbix modal
export function closeBerbixModal() {
  return {
    type: t.CLOSE_BERBIX_MODAL
  }
}
