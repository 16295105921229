import { createSelector } from 'reselect'

export const getAddressModalState = (state) => state.addressModal

export const getAddressSuggestions = createSelector([getAddressModalState], (addressModal) => addressModal.suggestions)

export const getResidentialOnlyError = createSelector(
  [getAddressModalState],
  (addressModal) => addressModal.residentialOnlyError
)
